import React, { useState, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Welcome.css';

function Welcome() {
  const images = useMemo(() => [
    "Uusitemppeli",
    "el_nido2",
    "Uusi_tori"
  ], []); 

  const [imageBaseName, setImageBaseName] = useState(images[0]);
  const { t } = useTranslation();

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % images.length;
      setImageBaseName(images[index]);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [images]);  

  return (
    <section 
      id="welcome" 
      style={{
        position: 'relative',
        height: '93vh', // Adjusted to a viewport-relative measurement
        overflow: 'hidden', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <picture>
          <source srcSet={`/images/${imageBaseName}.avif`} type="image/avif" />
          <source srcSet={`/images/${imageBaseName}.webp`} type="image/webp" />
          <img 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1
            }} 
            src={`/images/${imageBaseName}.jpg`} 
            alt={t(imageBaseName)}
          />
        </picture>
        <div className="text">
          <p>{t('welcome')}</p>
          <h2>{t('findNeeds')}</h2>
          <h1>{t('discover')}</h1>
        </div>
      </Container>
    </section>
  );
}

export default Welcome;
