import React from 'react';
import { useTranslation } from 'react-i18next';
import AliceCarousel from 'react-alice-carousel';
import './ProductsCarousel.css';
import 'react-alice-carousel/lib/alice-carousel.css';

// Responsive settings for the carousel
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

// Mapping image names to their corresponding alt text
const images = [
    { name: 'Chili_r', alt: 'Bright red chili peppers' },
    { name: 'dumblings', alt: 'Steamed dumplings on a plate' },
    { name: 'hotdog', alt: 'A hot dog with various toppings' },
    { name: 'katkarapu', alt: 'Fresh katkarapu on ice' },
    { name: 'Mustekalat', alt: 'Cooked mustekalat on a plate' },
    { name: 'rice', alt: 'Cooked white rice in a bowl' },
    { name: 'flip-1', alt: 'Flip-1 snack packaging' },
    { name: 'hylly', alt: 'Shelves (hylly) stocked with products' },
    { name: 'hylly1', alt: 'Different angle of shelves with products' },
    { name: 'sipsit', alt: 'Sipsit snacks on a white background' },
    { name: 'random', alt: 'A random assortment of snacks' },
    { name: 'noodels', alt: 'Cooked noodles with vegetables' },
  ];

const items = images.map((image, index) => (
  <picture key={image.name}>
    <source srcSet={`/images/${image.name}.avif`} type="image/avif" />
    <source srcSet={`/images/${image.name}.webp`} type="image/webp" />
    <img
      className="item"
      src={`/images/${image.name}.jpg`}
      alt={image.alt}
      loading="lazy"
      data-value={index + 1}
    />
  </picture>
));

const ProductsCarousel = () => {
  const { t } = useTranslation();

  return (
    <section id="products" className="carousel-section">
      <div className="carousel-header">
        <h2 className="carousel-title">{t('explore')}</h2>
        <h3>{t('contactUs')}</h3>
      </div>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
        stagePadding={{
          paddingLeft: 50,
          paddingRight: 50,
        }}
        duration={400}
      />
    </section>
  );
};

export default ProductsCarousel;