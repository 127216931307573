import React from 'react';
import { useTranslation } from 'react-i18next';
import './Location.css';

const Location = () => {
  const { t } = useTranslation();
  return (
    <section id="location" className="location-section">
      <h1 className='location-title'>{t('Location')}</h1>
      <div className="location-map-container">
        <iframe
          loading="lazy"
          title="Location"
          src="https://maps.google.com/maps?q=Piispanpiha%201,%20Espoo,%20Finland&t=&z=15&ie=UTF8&iwloc=&output=embed"
          allowFullScreen
        />
      </div>
    </section>
  );
};

export default Location;