import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ImageCarousel.css';

const ImageCarousel = ({ onSlideChange }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    onSlideChange(currentIndex);
  }, [currentIndex, onSlideChange]);

  const images = [
    { src: '/images/kuva1_r.webp', alt: 'Thai Market Olari' },
    { src: '/images/kuva2_r.webp', alt: 'Thai Market Olari' },
    { src: '/images/infoNok_r.webp', alt: 'Thai Market Olari' },
    { src: '/images/Nok_r.webp', alt: 'Thai Market Olari products' },
    { src: '/images/waiting.webp', alt: 'Thai Market Olari customers' },
    { src: '/images/MainNok.webp', alt: 'Thai Market Olari products' },
    { src: '/images/parkki.webp', alt: 'Thai Market Olari parking' }
  ];

  return (
    <Carousel autoPlay infiniteLoop interval={15000} className="Carousel" id="ImageCarouselID"
              onChange={setCurrentIndex}>
      {images.map((image, index) => (
        <div key={index}>
          <img className="carousel-image" src={image.src} alt={image.alt} loading="lazy" />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;