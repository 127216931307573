import React, { useMemo } from 'react';
import { Typography, Card } from '@mui/material';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

const AboutUs = ({ index }) => {
  const { t } = useTranslation();
  const text = t(`aboutUs.text${index + 1}`);

  // Added useMemo here to prevent unnecessary calculations during re-renders
  const textClass = useMemo(() => {
    if (text.length < 200) {
        return 'veryShort';
    } else if (text.length < 400) {
        return 'short';
    }
    return '';
  }, [text]);

  const name = 'Thai Market Olari';
  const nameIndex = text.indexOf(name);
  const nameLastIndex = nameIndex + name.length;

  // Also added useMemo here for similar reasons
  const [textStart, textEnd] = useMemo(() => [
    text.substring(0, nameIndex),
    text.substring(nameLastIndex)
  ], [text, nameIndex, nameLastIndex]);

  return (
    <section className='AboutUs' id="aboutus">
      <div>
        <h1 className='AboutUsTitle'>{t('About Us')}</h1>
        <Card className='AboutUsCard' sx={{ boxShadow: '5px 5px 15px red' }}>
          <div className='AboutUsTextBox'>
            <Typography>
              <span className={`AboutUsText ${textClass}`}>
                {textStart}
                <span className='AboutUsTextRed'>{name}</span>
                {textEnd}
              </span>
            </Typography>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default AboutUs;