import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";
import './Footer.css';
import { useTranslation } from 'react-i18next';

const SocialMediaLink = ({ IconComponent, link }) => (
  <a className='socialMediaSize' href={link} target="_blank" rel="noopener noreferrer">
    <IconComponent className="footer__icon" />
  </a>
);

const TinkerITSection = ({ deviceClass }) => {
  const { t } = useTranslation();
  return (
    <a className={`TinkerIT_section ${deviceClass}`} href="https://www.tinkerit.fi" target="_blank" rel="noopener noreferrer">
      <div className='tinkerIT_div'>
        <h3 className='tinkerIT_developed_text'>{t('tinkerIT')}</h3>
        <h3 className='tinkerIT_text'>TinkerIT Oy</h3>
        <picture>
          <source srcSet="/images/Tinkerit_valologo.avif" type="image/avif" />
          <source srcSet="/images/Tinkerit_valologo.webp" type="image/webp" />
          <img src="/images/Tinkerit_valologo.jpg" alt="TinkerIT Logo" loading="lazy" className="tinkerIT__logo" />
        </picture>
      </div>
    </a>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__left">
          <h3 className='footerTitle'>Thai Market Olari</h3>
          <h4>
            <a href="https://www.google.com/maps/search/?api=1&query=Piispanpiha+1,+Espoo,+Finland" target="_blank" rel="noopener noreferrer" className="footer__addressLink">
              Piispanpiha 1, Espoo, Finland
            </a>
          </h4>
          <h4><a href="mailto:nok.johnsson@gmail.com" className="footer__emailLink">nok.johnsson@gmail.com</a></h4>
          <h4><a href="tel:0408491044" className="footer__phoneLink">040 8491044</a></h4>

          <div className="footer__socialMedia">
            <SocialMediaLink IconComponent={FaFacebook} link="https://www.facebook.com/p/Thai-Market-Olari-Oy-100090049345938/" />
            <SocialMediaLink IconComponent={FaInstagram} link="https://www.instagram.com/thaimarketolari/" />
            <SocialMediaLink IconComponent={FaTiktok} link="https://www.tiktok.com/@thaimarketolari" />
            <SocialMediaLink IconComponent={FaWhatsapp} link="https://wa.me/358408491044" />
          </div>
          
          <TinkerITSection deviceClass="desktop" />
        </div>

        <div className="footer__right">
          <h4 className='openingHours_title'>{t('openingHours')}</h4>
          <ul>
            {daysOfWeek.map(day => <li key={day}>{t(day)}</li>)}
          </ul>
          <TinkerITSection deviceClass="mobile" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
