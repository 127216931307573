import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationFI from './locales/fi/translation.json';
import translationTH from './locales/th/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  },
  th: {
    translation: translationTH
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more languages can be added later
    fallbackLng: "en", // use en if detected lng is not available
    
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;