import React from 'react';
import './Oiva.css';
import { useTranslation, Trans } from 'react-i18next';

const Oiva = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={`oiva-container ${className}`}>
      <h3><strong>{t('Oiva Report')}</strong></h3>
      <Trans i18nKey="View our latest Oiva report">
        View our latest Oiva report <a href="https://oiva.ruokavirasto.fi/kohteet/1086904/raportit/1562185/pdf">here</a> and see why we are a trusted choice!
      </Trans>
    </div>
  );
};


export default Oiva;