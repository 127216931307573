import React, { useState } from 'react';
import './ThaiMarketOlari.css';
import ImageCarousel from './components/ImageCarousel';
import AboutUs from './components/AboutUs';
import Location from './components/Location';
import Footer from './components/Footer';
import Welcome from './components/Welcome';
import NavigationBar from './components/NavigationBar';
import ProductsCarousel from './components/ProductsCarousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent"; 
import Oiva from './components/Oiva';
import ParkingGuide from './components/ParkingGuide';
import ReactGA from "react-ga4";
import ParkingText from './components/ParkingText';

const ThaiMarketOlari = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  ReactGA.initialize("G-GCXL05V9C8");

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };


  return (
    <div>
      <NavigationBar />
      <Welcome />
      <Container fluid className='product-container'>
        <ProductsCarousel />
      </Container>
      <Container fluid className='product-about d-flex align-items-stretch'>
        <Row className='Row'>
          <Col>
            <Container fluid className='another-container'>
              <AboutUs index={currentIndex} />
            </Container>
            <Container>
              <Oiva className='oiva-desktop'/>
            </Container>
          </Col>
          <Col>
            <Container fluid className='carousel-container'>
              <ImageCarousel onSlideChange={handleSlideChange} /> 
            </Container>
            <Oiva className='oiva-mobile'/>
          </Col>
        </Row>
      </Container>
      <Location />
      <Container fluid className='product-about d-flex align-items-stretch'>
      <Row className='Row2'>
        <Col xs={12} md={6}>
          <Container fluid className='Imag-container'>
            <ParkingGuide />
          </Container>
        </Col>
        <Col xs={12} md={6}>
          <Container fluid className='tex-container'>
            <ParkingText />
          </Container>
        </Col>
      </Row>
      </Container>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#2B373B",
        }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "5px" }}
        expires={1} // This is in days. So this would be 1 day
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
};

export default ThaiMarketOlari;
