import React from 'react';
import "./ParkingText.css";
import { useTranslation } from 'react-i18next';

const ParkingText = () => {
  const { t } = useTranslation();

  return (
    <div className='parking-instructions pt-5'>
        <h1><strong>{t('Parking Instructions')}</strong></h1>
        <ul className='pt-2'>
            {Array.from({ length: 5 }).map((_, i) => (
                <li key={i} className='parkingtext'>
                    {t(`parkingInfo.text${i + 1}`)}
                </li>
            ))}
        </ul>
    </div>
  );
};

export default ParkingText;