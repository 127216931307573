import React from 'react';
import "./ParkingGuide.css";
import { useTranslation } from 'react-i18next';

const ParkingGuide = () => {
  const { t } = useTranslation();

  return (
    <div className='parking-guide pt-5'>
      <h1><strong>{t("Parking Area")}</strong></h1>
      <picture>
        <source srcSet='/images/Parking_r.avif' type="image/avif" />
        <source srcSet='/images/Parking_r.webp' type="image/webp" />
        <img className="parking-image" src='/images/Parking_r.jpg' alt={t("Parking Area")} loading="lazy" />
      </picture>
    </div>
  );
};

export default ParkingGuide;