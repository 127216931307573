import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import './NavigationBar.css';

const languages = [
  { code: 'en', imgSrc: 'united-kingdom', alt: 'English flag' },
  { code: 'fi', imgSrc: 'finland', alt: 'Finnish flag' },
  { code: 'th', imgSrc: 'thailand', alt: 'Thai flag' },
];

function NavigationBar() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [expanded, setExpanded] = useState(false); 
  const [navVisible, setNavVisible] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    collapseMenu();
  };

  const collapseMenu = () => setExpanded(false);

  useEffect(() => {
    const handleScroll = () => {
      if (expanded) {
        return;
      }
      const currentScrollPos = window.pageYOffset;
      const maxScrollPos = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const closeToBottom = currentScrollPos >= (maxScrollPos - 100);
      const closeToTop = currentScrollPos <= 150;
      const visible = (currentScrollPos <= lastScrollPos && !closeToBottom) || closeToTop;

      setNavVisible(visible);
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollPos, expanded]);

  return (
    <Navbar 
      collapseOnSelect 
      expand="lg" 
      expanded={expanded} 
      onToggle={() => setExpanded(!expanded)} 
      style={{backgroundColor: 'hwb(0 9% 24%)', transform: navVisible ? 'translateY(0)' : 'translateY(-100%)', transition: 'transform 200ms'}}
      variant="dark" 
      sticky="top"
    >
      <Navbar.Brand href='#'>
        <picture>
          <source srcSet="/images/Thaimarketlogo6.avif" type="image/avif" />
          <source srcSet="/images/Thaimarketlogo6.webp" type="image/webp" />
          <img 
            src="/images/Thaimarketlogo6.jpg"
            className="d-inline-block align-top logo"
            alt="Thai Market Logo"
          />
        </picture>
        Thai Market Olari
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <div className="nav-row">
            <ScrollLink
              onClick={collapseMenu} 
              activeClass="active"
              to="products"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
              className="nav-link nav-link-offset"
            >
              {t('Products')}
            </ScrollLink>
            <ScrollLink
              onClick={collapseMenu} 
              activeClass="active"
              to="aboutus"
              spy={true}
              smooth={true}
              offset={-210}
              duration={500}
              className="nav-link nav-link-offset"
            >
              {t('About Us')}
            </ScrollLink>
            <ScrollLink
              onClick={collapseMenu} 
              activeClass="active"
              to="location"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
              className="nav-link nav-link-offset"
            >
              {t('Location')}
            </ScrollLink>
          </div>
        </Nav>
        <Nav className="ml-auto">
          <Navbar.Text>
            {languages.map(({ code, imgSrc, alt }) => (
              <button 
                key={code}
                onClick={() => changeLanguage(code)}
                className={`nav-link nav-link-offset ${selectedLanguage === code ? 'active' : ''}`}
              >
                <picture>
                  <source srcSet={`/images/${imgSrc}.avif`} type="image/avif" />
                  <source srcSet={`/images/${imgSrc}.webp`} type="image/webp" />
                  <img 
                    className={`flag-icon ${selectedLanguage === code ? 'selected-language' : ''}`} 
                    src={`/images/${imgSrc}.jpg`} 
                    alt={alt} 
                    loading="lazy"
                  />
                </picture>
              </button>
            ))}
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;